import { COURSES_LIST } from "../../const";
import HeroSection from "./hero";
import DescriptionSection from "./description";
import { ChatBtn } from "../chat-btn";
export function SchedulesPage() {
  return (
    <main class="relative w-full h-full">
      <HeroSection />
      <DescriptionSection courses={COURSES_LIST} />
      <ChatBtn />
    </main>
  );
}
